import React, { Component } from 'react';

import insta from '../../img/logo-insta.png';
import './footer.css';
import { connect } from "react-redux";

export const parseAndReplacePhoneEmails = (content) =>
    content
    .replace(/(?:\+|\d)[\d\-\(\) ]{9,}\d/g, (phone) =>
        `<a href="tel:${phone.replace(/ /g, '')}">${phone}</a>`)
     .replace(/\w+@\w+\.\w+/g, (email) =>
     `<a href="mailto:${email}">${email}</a>`
);


export class FooterComponent extends Component {
    render() {
        return (
            <footer>
                <div className="container">
                    <div className="contactLeft" dangerouslySetInnerHTML={{ __html: parseAndReplacePhoneEmails(this.props.footerLeft.text) }}>
                    </div>

                    <div className="contactRight">
                        <a href="https://www.instagram.com/ivanovmodels/">
                            <img src={insta} width="30px" alt="instagram" />
                        </a>
                        <div dangerouslySetInnerHTML={{ __html: parseAndReplacePhoneEmails(this.props.footerRight.text) }} />
                    </div>
                </div>
            </footer>
        )
    }
}

export const Footer = connect(store => {
    const footerLeft = store.settings.find(value => value.place === 'footerLeftText');
    const footerRight = store.settings.find(value => value.place === 'footerRightText');
    return ({
        footerLeft,
        footerRight,
    })
})(FooterComponent);

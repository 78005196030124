import {combineReducers} from 'redux';
import {routerReducer} from 'react-router-redux';
import models from './models/models';
import settings from './settings/settings';

export default combineReducers({
    routing: routerReducer,
    models,
    settings,
})

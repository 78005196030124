// @flow
// import type {AjaxActionType} from 'constants/flow/flowTypes';

const initialState = window.initialState.models;
/**
 * Get join club result from backend
 *
 * @param {object} state Current state
 * @param {object} action Received action
 * @returns {object} Join club results
 */
export default function models(state = initialState, action){
    switch (action.type) {
        case 'MODELS_FULFILLED':
            return Object.assign({}, state, action.payload.data, {status: 'fulfilled'});
        case 'MODELS_PENDING':
            return {status: 'pending'};
        case 'MODELS_REJECTED':
            return Object.assign({}, state, action.payload.response.data, {status: 'rejected'});
        default:
            return state;
    }
}

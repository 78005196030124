import React, { PureComponent, Fragment } from 'react';
import { connect } from "react-redux";
import { Helmet } from "react-helmet"; 

import {parseAndReplacePhoneEmails} from '../../components/footer/footer';

import './about.css';

export class AboutComponent extends PureComponent {
    render() {
        return (
            <Fragment>
                <Helmet>
                    <title>About | Ivanov models</title>
                    <meta name="description" content="Helmet application" />
                </Helmet>
                <div className="about-con">
                    <div className="about-side">
                        <div className="inner" dangerouslySetInnerHTML={{ __html: parseAndReplacePhoneEmails(this.props.aboutUs.text) }} />
                    </div>
                    <div className="about-rightSide" />
                </div>
            </Fragment>
        )
    }
}


export const About = connect(store => {
    const aboutUs = store.settings.find(value => value.place === 'aboutUs');
    return ({
        aboutUs
    })
})(AboutComponent);

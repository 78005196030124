import React, { Component, Fragment } from 'react';
import { Route, Switch } from 'react-router-dom';
import { Helmet } from "react-helmet";

import { HeaderConnected } from "../../components/header/header";
import { HomeConnected } from "../home/home";
import { About } from "../about/about";
import { ModelConnected } from "../model/model";
import { Contact } from "../contact/contact";

import './app.css';

export class App extends Component {
    render() {
        return (
            <Fragment>
                <Helmet>
                    <title>Ivanov models</title>
                    <meta name="description" content="Helmet application" />
                </Helmet>
                <HeaderConnected />
                <div className="app">
                    <Switch>
                        <Route exact path="/" component={HomeConnected} />
                        <Route exact path="/about" component={About} />
                        <Route exact path="/become-a-model" component={Contact} />
                        {/*<Route component={() => <div>dynamic route</div>}/>*/}
                        <Route component={ModelConnected} />
                    </Switch>
                </div>
            </Fragment>
        )
    }
}

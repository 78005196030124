import React, {Component, Fragment} from 'react';
import {Link} from 'react-router-dom';
import {connect} from "react-redux";
import LazyLoad from 'react-lazyload';
import logoImg from '../../img/ilogo.png';
import {Footer} from "../../components/footer/footer";
import {scroller} from 'react-scroll';

import './home.css';

export class Home extends Component {
    getPhoto = (model) => {
        let photo = model.photos.main.find(photo => photo.id === model.mainfoto);
        if (!photo) return '';
        return `/foto/${model.foto}/${photo.filename}`;
    };

    getBadge = (model) => {
        switch (model.direct) {
            case '1':
                return '';
            case '2':
                return (<div className="badge badgeDirect">Direct</div>);
            case '3':
                return (<div className="badge badgeNewFace">New face</div>);
            case '4':
                return (<div className="badge badgeInternational">International</div>);
            default:
                return null;
        }
    };

    scroll = (event) => {
        console.log('event.currentTarget.dataset.target', event.currentTarget.dataset.target);
        scroller.scrollTo(event.currentTarget.dataset.target, {
            duration: 800,
            delay: 0,
            smooth: 'easeInOutQuart',
            offset: -100,
        });
    };

    modelsRender = (model, sex) => {
        if (model.pol === sex)
            return (
                <LazyLoad key={model.id} height={'300px'}>
                    <div className="model">
                        <Link to={`/${model.alias}`}>
                            <div className="photo">
                                <img src={this.getPhoto(model)} alt={`Model ${model.name}`}/>
                                {this.getBadge(model)}
                            </div>
                            <div className="name"><h2>{model.name}</h2></div>
                        </Link>
                    </div>
                </LazyLoad>
            );
        else
            return '';
    };

    render() {
        return (
            <Fragment>
                <header className="firstScreen">
                    <div className="logo-home">
                        <button data-target="female" onClick={this.scroll}>
                            <img src={logoImg} alt="Ivanov models"/>
                        </button>
                    </div>
                </header>

                <div className="models" id="modelsWrapper">
                    <div id="female">
                        <div className="modelsNavigation">
                            <span>Women</span>
                            <button data-target="male" onClick={this.scroll}>Men</button>
                        </div>
                        <div className="modelsGroup">
                            {this.props.models.map(model => {
                                return this.modelsRender(model, 'w')
                            })}
                        </div>
                    </div>
                    <div id="male" className="">
                        <div className="modelsNavigation">
                            <button data-target="female" onClick={this.scroll}>Women</button>
                            <span>Men</span>
                        </div>
                        <div className="modelsGroup">
                            {this.props.models.map(model => {
                                return this.modelsRender(model, 'm')
                            })}
                        </div>
                    </div>
                </div>
                <Footer/>
            </Fragment>
        )
    }
}

export const HomeConnected = connect(store => ({
    models: store.models,
}))(Home);

import React, { Component, Fragment } from 'react';
import { connect } from "react-redux";
import Flickity from 'react-flickity-component';
import { Helmet } from "react-helmet";

import './flickity.css';
import './model.css';

export class Model extends Component {
    state = {
        activePart: 'main',
    };

    sliderInstance;

    componentDidMount() {
        window.scrollTo(0, 0);
        const model = this.props.models.find(model => this.props.pathname.indexOf(model.alias.replace('/', '')) !== -1);
    }


    setPart = (event) => {
        this.setState({ activePart: event.currentTarget.dataset.part });
    };

    back = () => {
        window.history.back();
    };

    render() {
        const model = this.props.models.find(model => this.props.pathname.indexOf(model.alias.replace('/', '')) !== -1);
        if (!model)
            return null;
        return (
            <Fragment>
                <Helmet>
                    <title>Ivanov models | {model.name}</title>
                    <meta name="description" content="Helmet application" />
                </Helmet>
                <div className="model__container">
                    <div className="model__container__inner">
                        <div className="model__header">
                            <button className="backButton" onClick={this.back}>
                                Back
                        </button>
                            <div className="model__header__name">
                                {model.name}
                            </div>
                        </div>
                        <div className="model__info">
                            <div className="model__data">
                                <div className="model__parts">
                                    {(model.photos.trash.length !== 0 || model.videos.length !== 0) &&
                                        <span
                                            data-part="main"
                                            onClick={this.setPart}
                                            className={this.state.activePart === 'main' ? 'model__parts--active' : ''}
                                        >
                                            main
                                </span>
                                    }
                                    {model.photos.trash.length > 0 &&
                                        <span
                                            data-part="polaroids"
                                            onClick={this.setPart}
                                            className={this.state.activePart === 'polaroids' ? 'model__parts--active' : ''}
                                        >
                                            digital
                                </span>
                                    }
                                    {model.videos.length > 0 && model.videos.every(item => item.video) &&
                                        <span
                                            data-part="video"
                                            onClick={this.setPart}
                                            className={this.state.activePart === 'video' ? 'model__parts--active' : ''}
                                        >
                                            video
                                </span>
                                    }
                                </div>
                                <div className="model__data__field">
                                    <div className="model__data__label">location:</div>
                                    <div className="model__data__value">{model.fam}</div>
                                </div>
                                <div className="model__data__field">
                                    <div className="model__data__label">height:</div>
                                    <div className="model__data__value">{model.rost}</div>
                                </div>
                                <div className="model__data__field">
                                    <div className="model__data__label">bust:</div>
                                    <div className="model__data__value">{model.grud}</div>
                                </div>
                                <div className="model__data__field">
                                    <div className="model__data__label">waist:</div>
                                    <div className="model__data__value">{model.taliya}</div>
                                </div>
                                <div className="model__data__field">
                                    <div className="model__data__label">hips:</div>
                                    <div className="model__data__value">{model.bedra}</div>
                                </div>
                                <div className="model__data__field">
                                    <div className="model__data__label">shoe size:</div>
                                    <div className="model__data__value">{model.shoe}</div>
                                </div>
                                <div className="model__data__field">
                                    <div className="model__data__label">hair color:</div>
                                    <div className="model__data__value">{model.hair}</div>
                                </div>
                                <div className="model__data__field">
                                    <div className="model__data__label">eye color:</div>
                                    <div className="model__data__value">{model.eyes}</div>
                                </div>
                                {model.instagram_url &&
                                    <a className="model__data__field" href={model.instagram_url}>
                                        <span className="model__data__label">Instagram:</span>
                                        <span className="model__data__value">
                                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M352 0H160A160 160 0 000 160v192a160 160 0 00160 160h192a160 160 0 00160-160V160A160 160 0 00352 0zm112 352c0 61.8-50.2 112-112 112H160c-61.8 0-112-50.2-112-112V160C48 98.2 98.2 48 160 48h192c61.8 0 112 50.2 112 112v192z" /><path d="M256 128a128 128 0 100 256 128 128 0 000-256zm0 208a80.1 80.1 0 010-160 80 80 0 010 160z" /><circle cx="393.6" cy="118.4" r="17.1" /></svg>
                                        </span>
                                    </a>
                                }
                                {model.pdf && (
                                    <div className="model__data__field">
                                        <div className="model__data__label">Comp Card:</div>
                                        <div className="model__data__value">
                                            <a href={`/foto/${model.foto}/${model.pdf}`}>
                                                <svg xmlns="http://www.w3.org/2000/svg" version="1.1" id="Capa_1" x="0px" y="0px" viewBox="0 0 438.533 438.533">
                                                    <g>
                                                        <path d="M409.133,109.203c-19.608-33.592-46.205-60.189-79.798-79.796C295.736,9.801,259.058,0,219.273,0   c-39.781,0-76.47,9.801-110.063,29.407c-33.595,19.604-60.192,46.201-79.8,79.796C9.801,142.8,0,179.489,0,219.267   c0,39.78,9.804,76.463,29.407,110.062c19.607,33.592,46.204,60.189,79.799,79.798c33.597,19.605,70.283,29.407,110.063,29.407   s76.47-9.802,110.065-29.407c33.593-19.602,60.189-46.206,79.795-79.798c19.603-33.596,29.403-70.284,29.403-110.062   C438.533,179.485,428.732,142.795,409.133,109.203z M361.733,204.705L232.119,334.324c-3.614,3.614-7.9,5.428-12.849,5.428   c-4.948,0-9.229-1.813-12.847-5.428L76.804,204.705c-3.617-3.615-5.426-7.898-5.426-12.845c0-4.949,1.809-9.235,5.426-12.851   l29.119-29.121c3.621-3.618,7.9-5.426,12.851-5.426c4.948,0,9.231,1.809,12.847,5.426l87.65,87.65l87.65-87.65   c3.614-3.618,7.898-5.426,12.847-5.426c4.949,0,9.233,1.809,12.847,5.426l29.123,29.121c3.621,3.616,5.428,7.902,5.428,12.851   C367.164,196.807,365.357,201.09,361.733,204.705z" />
                                                    </g>
                                                </svg>
                                            </a></div>
                                    </div>
                                )}
                            </div>
                            <Flickity
                                className="glide__container"
                                reloadOnUpdate
                                options={{
                                    groupCells: true,
                                    wrapAround: true,
                                }}
                            >
                                {/* {this.state.activePart !== 'video' && <div className="glide_zag" />} */}
                                {this.state.activePart === 'main' && model.photos['main'].sort(
                                    (a, b) => {
                                        if (Number(a.rank) > Number(b.rank)) {
                                            return 1;
                                        }
                                        if (Number(a.rank) < Number(b.rank)) {
                                            return -1;
                                        }
                                    }
                                ).map(photo => (
                                    <div key={photo.id} className="glide__imgWrap">
                                        <img  src={`/foto/${model.foto}/${photo.filename}`} alt={model.name} />
                                    </div>
                                ))}
                                {this.state.activePart === 'polaroids' && model.photos['trash'].sort(
                                    (a, b) => {
                                        if (Number(a.rank) > Number(b.rank)) {
                                            return 1;
                                        }
                                        if (Number(a.rank) < Number(b.rank)) {
                                            return -1;
                                        }
                                    }
                                ).map(photo => (
                                    <div key={photo.id} className="glide__imgWrap">
                                        <img src={`/foto/${model.foto}/trash/${photo.filename}`} alt={model.name} />
                                    </div>
                                ))}
                                {this.state.activePart === 'video' && model.videos.map(video => (
                                    <div key={video.id} className="glide__slide glide__slide__video"
                                        dangerouslySetInnerHTML={{ __html: video.video }}>
                                    </div>
                                ))}
                            </Flickity>
                        </div>
                    </div>
                </div>
            </Fragment >
        )
    }
}

export const ModelConnected = connect(store => ({
    models: store.models,
    pathname: store.routing.location.pathname,
    r: store.routing,
}))(Model);

import React, {Component, Fragment} from 'react';
import classNames from 'classnames';
import {Link} from 'react-router-dom';
import {connect} from "react-redux";
import logoImg from '../../img/ilogo.png';

import './header.css';


export class Header extends Component {
    state = {
        isMenuOpened: false,
        isLogoShow: false,
    };

    componentDidMount() {
        if (this.props.pathname === '/') {
            document.addEventListener('scroll', this.watchLogoShow);
        }
    }

    componentWillUnmount() {
        document.removeEventListener('scroll', this.watchLogoShow);
    }

    componentDidUpdate(prevProps) {
        if (this.props.locationKey !== prevProps.locationKey) {
            this.setState({isMenuOpened: false});
        }
    }

    watchLogoShow = () => {
        let element = document.querySelector('#modelsWrapper');
        if(!element)
            return;
        if (element.getBoundingClientRect().top < 0) {
            if (this.state.isLogoShow !== true) {
                this.setState({isLogoShow: true});
            }
        } else {
            if (this.state.isLogoShow !== false) {
                this.setState({isLogoShow: false});
            }
        }
    };

    toggleMenu = () => {
        this.setState({isMenuOpened: !this.state.isMenuOpened})
    };

    render() {
        return (
            <Fragment>
                <header>
                    <div onClick={this.toggleMenu}>
                        <nav className="menu-opener">
                            <div className={`dots ${this.state.isMenuOpened ? 'dots-opened' : ''}`}/>
                        </nav>
                    </div>
                    <div
                        className={`
                        header__logo
                        ${this.props.pathname === '/' ? 'header__logo__hp' : ''}
                        ${this.state.isLogoShow ? 'header__logo--visible' : ''}
                        ${this.props.pathname === '/become-a-model' || this.props.pathname === '/about' ? 'header__logo--contact' : ''}
                        `}
                    >
                        <Link to="/"><img src={logoImg} alt="Ivanov models"/></Link>
                    </div>
                    <div className="menu">

                    </div>
                </header>
                <div className={classNames('menu__overlay', {'menu__overlay--opened': this.state.isMenuOpened})}>
                    <div className="menu-inner">
                        <Link to='/' className="menu-item">IVANOV MODELS</Link>
                        <Link to='/#models' className="menu-item">Models</Link>
                        <Link to='/become-a-model' className="menu-item">Apply</Link>
                        <Link to='/about' className="menu-item">About us</Link>
                        <a target="_blank" rel="noopener noreferrer" href="http://instagram.com/ivanovmodels" className="menu-item">
                            Instagram
                        </a>
                    </div>
                </div>
            </Fragment>
        )
    }
}

export const HeaderConnected = connect(store => ({
    locationKey: store.routing.location.key,
    pathname: store.routing.location.pathname,
}))(Header);

import React, { Component, Fragment } from 'react';
import { connect } from "react-redux";
import axios from 'axios';
import { Helmet } from "react-helmet";

import './contact.css';

const InputField = ({ id, name, value, placeHolder, error, type = 'text', onChange }) => {
    return (
        <>
            <p>
                <label htmlFor={id}>
                    <input onChange={onChange} type={type} id={id} name={name} placeholder={placeHolder} value={value}
                        size="30" />
                </label>
                {error && <span className="error">This is the required field</span>}
            </p>
        </>
    )
};

export class ContactComponent extends Component {
    state = {
        fields: {
            name: { value: '', error: '' },
            sureName: { value: '', error: '' },
            email: { value: '', error: '' },
            bust: { value: '', error: '' },
            waist: { value: '', error: '' },
            hips: { value: '', error: '' },
            shoes: { value: '', error: '' },
            haircolor: { value: '', error: '' },
            eyescolor: { value: '', error: '' },
            dateBirth: { value: '', error: '' },
            file1: { value: '', error: '' },
            file2: { value: '', error: '' },
            file3: { value: '', error: '' },
            height: { value: '170', error: '' },
            instagram: { value: '', error: '' },
        },
        status: 'initial',
    };

    onChangeInput = (event) => {
        this.setState({ fields: { ...this.state.fields, [event.target.name]: { value: event.target.value, error: '' } } });
    };

    onChangeFile = (event) => {
        this.setState({ fields: { ...this.state.fields, [event.target.name]: { value: event.target.files[0], error: '' } } });
    };

    validation = () => {
        const newState = {};
        Object.keys(this.state.fields).forEach(field => {
            if (this.state.fields[field].value === '') {
                newState[field] = { ...this.state.fields[field], error: 'error' };
            }
        });
        this.setState({ fields: { ...this.state.fields, ...newState } });
        return Object.keys(newState).every(field => newState[field].error === '')
    };

    submit = (event) => {
        event.preventDefault();
        if (this.validation()) {
            const data = new FormData();

            data.append('name', this.state.fields.name.value);
            data.append('sureName', this.state.fields.sureName.value);
            data.append('email', this.state.fields.email.value);
            data.append('bust', this.state.fields.bust.value);
            data.append('waist', this.state.fields.waist.value);
            data.append('hips', this.state.fields.hips.value);
            data.append('shoes', this.state.fields.shoes.value);
            data.append('haircolor', this.state.fields.haircolor.value);
            data.append('eyescolor', this.state.fields.eyescolor.value);
            data.append('dateBirth', this.state.fields.dateBirth.value);
            data.append('height', this.state.fields.height.value);
            data.append('file1', this.state.fields.file1.value);
            data.append('file2', this.state.fields.file2.value);
            data.append('file3', this.state.fields.file3.value);
            data.append('instagram', this.state.fields.instagram.value);
            this.setState({ status: 'pending' });
            axios.post(
                window.location.origin + '/send',
                data,
                { headers: { 'content-type': 'multipart/form-data' } }
            ).then(data1 => {
                console.log('DATA', data1);
                this.setState({ status: 'fulfilled' });
            }).catch(data => {
                this.setState({ status: 'rejected' });
            })
        }
    };

    render() {
        return (
            <Fragment>
                <Helmet>
                    <title>Become a model | Ivanov models</title>
                    <meta name="description" content="Helmet application" />
                </Helmet>
                <div className="contact-con">
                    <div className="form">
                        <div className="inner">
                            <div className="contact-header">Model Application</div>
                            <p className="contact-text" dangerouslySetInnerHTML={{ __html: this.props.applyPage.text }} />
                            <form>
                                <InputField
                                    id="name"
                                    name="name"
                                    value={this.state.fields.name.value}
                                    placeHolder="What is your first name *"
                                    error={this.state.fields.name.error}
                                    onChange={this.onChangeInput}
                                />
                                <InputField
                                    id="sureName"
                                    name="sureName"
                                    value={this.state.fields.sureName.value}
                                    placeHolder="Sure name *"
                                    error={this.state.fields.sureName.error}
                                    onChange={this.onChangeInput}

                                />
                                <InputField
                                    id="email"
                                    name="email"
                                    value={this.state.fields.email.value}
                                    placeHolder="Email *"
                                    type="email"
                                    error={this.state.fields.email.error}
                                    onChange={this.onChangeInput}

                                />

                                <InputField
                                    id="instagram"
                                    name="instagram"
                                    value={this.state.fields.instagram.value}
                                    placeHolder="Instagram url*"
                                    type="text"
                                    error={this.state.fields.instagram.error}
                                    onChange={this.onChangeInput}

                                />

                                <p className="input-grey input-size">
                                    <label htmlFor="height">Height *
                                    <select id="height" name="height" value={this.state.fields.height.value}
                                            onChange={this.onChangeInput}>
                                            <option value="170">170</option>
                                            <option value="171">171</option>
                                            <option value="172">172</option>
                                            <option value="173">173</option>
                                            <option value="174">174</option>
                                            <option value="175">175</option>
                                            <option value="176">176</option>
                                            <option value="177">177</option>
                                            <option value="178">178</option>
                                            <option value="179">179</option>
                                            <option value="180">180</option>
                                            <option value="181">181</option>
                                            <option value="182">182</option>
                                            <option value="183">183</option>
                                            <option value="184">184</option>
                                            <option value="185">185</option>
                                            <option value="186">186</option>
                                            <option value="187">187</option>
                                            <option value="188">188</option>
                                            <option value="189">189</option>
                                            <option value="190">190</option>
                                            <option value="191">191</option>
                                            <option value="192">192</option>
                                            <option value="193">193</option>
                                            <option value="194">194</option>
                                            <option value="195">195</option>
                                            <option value="196">196</option>
                                            <option value="197">197</option>
                                            <option value="198">198</option>
                                            <option value="199">199</option>
                                            <option value="200">200</option>
                                            <option value="201">201</option>
                                            <option value="202">202</option>
                                            <option value="203">203</option>
                                            <option value="204">204</option>
                                            <option value="205">205</option>
                                            <option value="206">206</option>
                                            <option value="207">207</option>
                                            <option value="208">208</option>
                                            <option value="209">209</option>
                                            <option value="210">210</option>
                                        </select>
                                    cm</label>
                                </p>

                                <InputField
                                    id="bust"
                                    name="bust"
                                    value={this.state.fields.bust.value}
                                    placeHolder="Bust *"
                                    error={this.state.fields.bust.error}
                                    onChange={this.onChangeInput}

                                />
                                <InputField
                                    id="waist"
                                    name="waist"
                                    value={this.state.fields.waist.value}
                                    placeHolder="Waist"
                                    error={this.state.fields.waist.error}
                                    onChange={this.onChangeInput}

                                />
                                <InputField
                                    id="hips"
                                    name="hips"
                                    value={this.state.fields.hips.value}
                                    placeHolder="Hips"
                                    error={this.state.fields.hips.error}
                                    onChange={this.onChangeInput}
                                />
                                <InputField
                                    id="shoes"
                                    name="shoes"
                                    value={this.state.fields.shoes.value}
                                    placeHolder="Shoes"
                                    error={this.state.fields.shoes.error}
                                    onChange={this.onChangeInput}
                                />
                                <InputField
                                    id="haircolor"
                                    name="haircolor"
                                    value={this.state.fields.haircolor.value}
                                    placeHolder="Hair Color"
                                    error={this.state.fields.haircolor.error}
                                    onChange={this.onChangeInput}
                                />
                                <InputField
                                    id="eyescolor"
                                    name="eyescolor"
                                    value={this.state.fields.eyescolor.value}
                                    placeHolder="Eyes Color"
                                    error={this.state.fields.eyescolor.error}
                                    onChange={this.onChangeInput}
                                />
                                <InputField
                                    id="dateBirth"
                                    name="dateBirth"
                                    value={this.state.fields.dateBirth.value}
                                    placeHolder="Date of birth"
                                    error={this.state.fields.dateBirth.error}
                                    onChange={this.onChangeInput}
                                />

                                <div className="piecesjointes">

                                    <div id="file1" className="fileInput">
                                        <label htmlFor="fileToUpload1" id="label1">And submit 3 photos of yourself</label>
                                        <input onChange={this.onChangeFile} type="file" name="file1" id="fileToUpload1"
                                            accept="image/*" />
                                        {this.state.fields.file1.error && <div className="error">This is the required field</div>}
                                    </div>

                                    <div id="file2" className="fileInput">
                                        <label htmlFor="fileToUpload2" id="label2" className="upload-label"></label>
                                        <input onChange={this.onChangeFile} type="file" name="file2" id="fileToUpload2"
                                            accept="image/*" />
                                        {this.state.fields.file2.error && <div className="error">This is the required field</div>}
                                    </div>
                                    <div id="file3" className="fileInput">
                                        <label htmlFor="fileToUpload3" id="label3" className="upload-label"></label>
                                        <input onChange={this.onChangeFile} type="file" name="file3" id="fileToUpload3"
                                            accept="image/*" />
                                        {this.state.fields.file3.error && <div className="error">This is the required field</div>}
                                    </div>
                                </div>

                                <div className="button">
                                    <button disabled={this.state.status === 'pending' || this.state.status === 'fulfilled'} type="submit" onClick={this.submit}>
                                        {this.state.status === 'initial' ? 'Send' : ''}
                                        {this.state.status === 'pending' ? 'Sending...' : ''}
                                        {this.state.status === 'fulfilled' ? 'Sent' : ''}
                                    </button>
                                    <div>{this.state.status === 'fulfilled' ? 'Thanks for you message' : ''}</div>
                                    <div>{this.state.status === 'rejected' ? 'Something is wrong. Try again later.' : ''}</div>
                                </div>

                            </form>
                        </div>
                    </div>
                    <div className="contact-rightSide" />
                </div>
            </Fragment>
        )
    }
}

export const Contact = connect(store => {
    const applyPage = store.settings.find(value => value.place === 'applyPage');
    return ({
        applyPage,
    })
})(ContactComponent);
